import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/config';

export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    return { token, username }; 
  });
  const [loading, setLoading] = useState(true);

  // Check for token and set user
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const username = localStorage.getItem('username'); // Retrieve username
      setUser({ token, username }); // Set user state with token and username
    }
    setLoading(false);
  }, []);

  // Login function
  const login = async (usernameOrEmail, password) => {
    try {
       setLoading(true); 
      const response = await axiosInstance.post('/admin_login', {
        usernameOrEmail,
        password,
      });
      const { token, username } = response.data; // Assuming username is part of the response
      
      localStorage.setItem('token', token);
      localStorage.setItem('username', username); // Store username in local storage
      setUser({ token, username }); // Include username in the user object
      
      console.log(localStorage.getItem('username'));
    } catch (error) {
      if (!error.response) {
        throw new Error('Network error: Unable to reach the server. Please try again later.');
      }
      throw new Error(error.response.data.message || 'Login failed. Please check your credentials.');
    } finally {
      setLoading(false); // Stop loading after the request is completed
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username'); 
    localStorage.removeItem('activeComponent');
    setUser({ token: null, username: null }); 
  };

  const value = { user, loading, login, logout };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
