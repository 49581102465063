import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress,TextField, Button, Typography, Box, InputAdornment , Link as MUILink } from '@mui/material';
import { styled } from '@mui/system';
import { AuthContext } from '../../utils/AuthContext';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      transition: 'border-color 0.5s ease',
    },
    '&:hover fieldset': {
      borderColor: '#00bfa5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#052560',
    },
  },
  '& .MuiInputLabel-outlined': {
    color: '#00bfa5',
    transition: 'color 0.3s ease',
    '&.Mui-focused': {
      color: '#052560',
    },
  },
  marginBottom: '20px',
}));

const AdminLogin = () => {
  const { login, loading } = useContext(AuthContext);
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      await login(usernameOrEmail, password);
      navigate('/admin_dashboard'); // Navigate on successful login
    } catch (err) {
      setError(err.message || 'Login failed')// Display error message
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          backgroundColor: '#fff',
          padding: '40px',
          borderRadius: '20px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" sx={{ color: '#052560', fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
          Administrator
        </Typography>

        {error && <Typography color="error" sx={{ textAlign: 'center' }}>{error}</Typography>}

        <form onSubmit={handleSubmit}>
          <StyledTextField
            label="Username or Email"
            variant="outlined"
            required
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            placeholder="administrator@email.com"
            fullWidth
          />
          <StyledTextField
            label="Password"
            variant="outlined"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="********"
            fullWidth
            sx={{ mt: 2 }}
          />
          <MUILink
            href="#"
            sx={{
              color: 'red',
              mb: 2,
              display: 'block',
              textAlign: 'center',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Reset password
          </MUILink>

          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              backgroundColor: '#001f72',
              color: '#fff',
              borderRadius: '25px',
              padding: '10px 50px',
              width: '100%',
              transition: 'background-color 0.8s ease',
              '&:hover': {
                backgroundColor: '#00bfa5',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default AdminLogin;
