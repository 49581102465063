import React, { useEffect,useState } from 'react';
import { Box, Card, Grid, TextField, Typography, Button, IconButton, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import {Search,Add,DeleteForever,Edit,SupportAgentOutlined} from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../../utils/config';


//globa mui input styling
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      transition: 'border-color 0.5s ease',
    },
    '&:hover fieldset': {
      borderColor: '#00bfa5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#052560',
    },
  },
  '& .MuiInputLabel-outlined': {
    color: '#00bfa5',
    transition: 'color 0.3s ease',
    '&.Mui-focused': {
      color: '#052560',
    },
  },
  marginBottom: '16px',
}));





const Distributions = () => {
  const [serialNo, setSerialNo] = useState('');
  const [outletOptions, setOutletOptions] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(''); 
  const [serialList, setSerialList] = useState([]);
  const [isBatchMode, setIsBatchMode] = useState(false);
  const [isBranchLocked, setIsBranchLocked] = useState(false); // To lock branch dropdown
  const [itemToDelete, setItemToDelete] =  useState('');

  const [loading, setLoading] = useState(false);
  const [sendingbatch, setSendingBatch] = useState(false);



  const handleAddSerial = () => {
    setSerialList([...serialList, serialNo]);
    setSerialNo('');
    setIsBatchMode(true);
    setIsBranchLocked(true); // Lock branch dropdown after adding the first serial
  };

  const handleSendSerial = () => {

    // Send single serial logic here
    const data = { serial: serialNo, outlet: selectedOutlet};
    setLoading(true);
    axiosInstance.post('/send_serial', data)
      .then((response) => {
        console.log(response.data);
        alert('Serial sent successfully');
        setSerialNo(''); 
        setSelectedOutlet('');  
      })
      .catch((error) => {
        console.error('Error sending serial:', error);
      })
      .finally(() => {
        setLoading(false); 
      });
   
  };

  const handleSendBatch = () => {
   

         if (!selectedOutlet) {
        alert("Please select a branch");
         setIsBranchLocked(false);
        return;
    }
    // Send batch logic here
    
    serialList.forEach(serial => {
        setSendingBatch(true);
       const data = { serial: serial, outlet: selectedOutlet};

      axiosInstance.post('/send_serial', data)
        .then((response) => {
            
          console.log(response.data);
        })
        .catch((error) => {
          console.error('Error sending batch:', error);
        }).finally(() => {
        setSendingBatch(false); 
      });
    });

    alert('batch sent');
    setIsBranchLocked(false);
    setSerialList([]);
    setIsBatchMode(false);
    setSelectedOutlet(''); 
     
  };

  const handleDeleteSerial = (index) => {
    const updatedList = [...serialList];
    updatedList.splice(index, 1);
    setSerialList(updatedList);

    };

  const handleCancel = () => {
    setSerialList([]);
    setIsBatchMode(false);
    setIsBranchLocked(false); // Unlock branch dropdown when canceling
  };
    // Handle form input changes
// const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value, // Update form data as user types
//         }));
//     };


      //Load branches from the database u  
useEffect(() => {
              axiosInstance.get('/get_outlets')
          .then((response) => {
            // Filter the needed fields (id and outlet)
            const filteredOptions = response.data.map(item => ({
              id: item.id,
              outlet: item.outlet,
            }));
            setOutletOptions(filteredOptions);
          })
          .catch((error) => {
            console.error('Error fetching dropdown data:', error);
          });
      }, []);

  //PREPARE DISTRIBUTION DATA FROM API 
  const [data, setData] = useState([]); 
  
    // Fetch data from the API
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/get_pending_serials'); // Adjust the endpoint as needed
            setData(response.data);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Use useEffect to fetch data on component mount
    useEffect(() => {
        fetchData();
        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 1 second
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

//convert date 
    const formatDate = (dateString) => {
    const date = new Date(dateString);
        return date.toISOString().split('T')[0]; 
      
    };

//handle delete serial
 const handleDelete = async (itemToDelete) => {
    console.log('item to delete id: ', itemToDelete);

        try {

            const response = await axiosInstance.delete(`/ad/delete_pending/:id/${itemToDelete}`); 
            console.log('serial removed:', response.data);
            alert('serial number has been removed!');
           
        } catch (error) {
            console.error('can not remove serial at this time:', error);
        }
    };
  return (
   <section>
    <div className='distribution-form'>
         <Box sx={{ padding: 2, position: 'relative' }}>
   
      <Card sx={{ padding: 4, marginTop: 2, borderRadius: 2 }}>
   
        <Grid container spacing={2}>  
                    <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          top: '-20px',
          left: '20px',
          backgroundColor: 'white',
          padding: '0 10px',
        }}
      >
        Distribute Sim Card
      </Typography>
          <Grid item xs={12} md={6}>
             <Typography variant="subtitle1" sx={{paddingLeft:'50px'}}>Scan or Type Serial Number</Typography>
            < StyledTextField
              label="Select Branch"
              variant="outlined"
              fullWidth
              select
              name="branch"
              value={selectedOutlet}
              onChange={(e) => setSelectedOutlet(e.target.value)}
              required
              enable = {serialList.length == 20}
           

                slotProps={{
                    input: {
                           disabled:isBranchLocked
                    },
        
                }}
             
            >
              {outletOptions.map((option) => (
                <MenuItem key={option.id} value={option.outlet}>
                  {option.outlet}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              fullWidth
              label="Enter Serial No."
              variant="outlined"
              value={serialNo}
              onChange={(e) => setSerialNo(e.target.value)}
              inputProps={{ maxLength: 20 }}
              sx={{ marginTop: 2 }}
            />  
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item>
            <Button
                            variant="contained"  
                            onClick={handleAddSerial}
                            startIcon = {<Add  sx = {{fontSize:30}}/> }
                            disabled ={serialNo.length !== 20}
                            sx={{
                            backgroundColor: '#001f72',
                            color: '#fff',
                            fontFamily:'Tilt Neon, sans-serif',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            width: { xs: '100%', md: '200px' },
                            marginBottom: { xs: '16px', md: '0' },
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#00bfa5',
                                transition: 'background-color 0.3s ease',
                            },
                                '& .MuiButton-startIcon': {
                                marginRight: '8px', // Add space between the icon and the text
                                },
                            }}
                            
                        >
                            Add
                        </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<SendIcon />}
                  onClick={handleSendSerial}
                  disabled={isBatchMode || serialNo.length !== 20}
                  sx={{
                            backgroundColor: '#001f72',
                            color: '#fff',
                            fontFamily:'Tilt Neon, sans-serif',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            width: { xs: '100%', md: '200px' },
                            marginBottom: { xs: '16px', md: '0' },
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#00bfa5',
                                transition: 'background-color 0.3s ease',
                            },
                                '& .MuiButton-startIcon': {
                                marginRight: '8px', // Add space between the icon and the text
                                },
                            }}
                >
                 {loading ? 'Sending Serial...' : 'Send'}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Serial list, cancel, and send batch button */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="subtitle1">Added Serial Number</Typography>
              <Box
                sx={{
                  border: '1px solid lightgray',
                  padding: 1,
                  borderRadius: 1,
                  height: '150px',
                  overflowY: 'auto',
                }}
              >
                {serialList.map((serial, index) => (
                  <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>{serial}</Typography>
                    <IconButton size="small" onClick={() => handleDeleteSerial(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Typography variant="caption">{serialList.length} serials added</Typography>

              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item>
                  <Button 
                  variant="contained" 
                  onClick={handleCancel}
                  sx={{
                            backgroundColor: 'red',
                            color: '#fff',
                            fontFamily:'Tilt Neon, sans-serif',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            width: { xs: '100%', md: '200px' },
                            marginBottom: { xs: '16px', md: '0' },
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#00bfa5',
                                transition: 'background-color 0.3s ease',
                            },
                                '& .MuiButton-startIcon': {
                                marginRight: '8px', // Add space between the icon and the text
                                },
                            }}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                     startIcon={<SendIcon />}
                    onClick={handleSendBatch}
                    disabled={serialList.length === 0}

                    sx={{
                            backgroundColor: '#001f72',
                            color: '#fff',
                            fontFamily:'Tilt Neon, sans-serif',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            width: { xs: '100%', md: '200px' },
                            marginBottom: { xs: '16px', md: '0' },
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#00bfa5',
                                transition: 'background-color 0.3s ease',
                            },
                                '& .MuiButton-startIcon': {
                                marginRight: '8px', // Add space between the icon and the text
                                },
                            }}
                  >
                     {sendingbatch ? 'Sending batch...' : 'Send Batch'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
    </div>

      
  
    <div className = 'distribution-table'>
           <h1>Serial Numbers Sent</h1>
           <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Seiral Number</th>
                              <th>Outlet</th>
                              <th>Date</th>
                              <th>Status</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.serial}</td>
                                <td>{row.shop}</td>
                                <td>{formatDate(row.date_added)}</td>
                                <td>
                                  <span style={{color:'orange'}}>pending</span>
                                </td>
                                {/* <td>
                                  <span className="action-remove">remove</span>
                                
                                  <DeleteForever onClick={() => handleDelete(setItemToDelete(row.serial_id))}   
                                  sx={{ cursor: 'pointer', color:'#05256060',
                                  '&:hover': {
                                    color: 'red',
                                    transition: 'color 0.3s ease',
                                    }, }}/>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                          
                        </table>
                        <p style={{fontWeight: 600, fontStyle:'bold', fontFamily:'Quicksand, sans-serif'}}>total serials : {data.length} </p>
               
              </div> 
    </div>
   </section>
  );
};

export default Distributions;
