// StatCard.js
import React from 'react';
import './styles/statcard.css'; // Add your CSS file here

const StatCard = ({ title, value, description,icon }) => {
  return (
    <div className="stat-card">
      
      <div className="stat-card-info">
            <div className='left'>
                <h3 className="stat-card-title">{title}</h3>
                <p className="stat-card-value">{value}</p>
            </div>
            <div className='right'>
                <div className="stat-card-icon">{icon}</div>
            </div>
      </div>

      <div className="stat-card-desc">
         <p className="stat-card-description">{description}</p>
      </div>
     
      
    </div>
  );
};

export default StatCard;
