// src/components/Sidebar.js

import React from 'react';
import './styles/sideBar.css';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isOpen, setActiveComponent, onLinkClick  }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <ul className="sidebar-list">
        <li onClick={() => {setActiveComponent('home'); onLinkClick();}}>Dashboard</li>
        <li onClick={() => {setActiveComponent('agents'); onLinkClick()}}>Agents</li>
        <li>
          <span>Sim Cards</span> 
          <ul className="sublinks">
            <li onClick={() => {setActiveComponent('distributions'); onLinkClick()}}>Distribution</li>
            <li onClick={() => {setActiveComponent('submissions'); onLinkClick()}}>Submissions</li>
          </ul>
        </li>
        <li onClick={() => {setActiveComponent('outlets'); onLinkClick()}}>Outlets</li>
        <li onClick={() => {setActiveComponent('messages'); onLinkClick()}}>Messages</li>
        <br/>
        <br/>
        <hr />
        <br/>
       
        <li onClick={() => {setActiveComponent('outlets'); onLinkClick()}}>Profile</li>
        <li onClick={() => {setActiveComponent('outlets'); onLinkClick()}}>Settings</li>
        <li>Log Out</li>
      </ul>
    </div>
  );
};

export default Sidebar;
