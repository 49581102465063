import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box,InputAdornment,Link as MUILink, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import {Search,Add,DeleteForever,Edit,SupportAgentOutlined} from '@mui/icons-material';
import axiosInstance from '../../utils/config';

import './popups/styles/Popup.css';
import './styles/outlets.css';


const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      transition: 'border-color 0.5s ease',
    },
    '&:hover fieldset': {
      borderColor: '#00bfa5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#052560',
    },
  },
  '& .MuiInputLabel-outlined': {
    color: '#00bfa5',
    transition: 'color 0.3s ease',
    '&.Mui-focused': {
      color: '#052560',
    },
  },
  marginBottom: '16px',
}));


 
const Agents= ({exportToexcel}) => {
  
    /* PopUp open and Send data*/
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);


/*prepare our form data for submitting*/
 const [formData, setFormData] = useState({
    agent_id:'',
    full_names: '',
    OTA_agent_SSO: '',
    branch:'',
    phone: '',
    email: '',
  });

// Function to open the modal for adding a new record
  const handleClickOpen = () => {
        setFormData({ agent_id:'',full_names: '', OTA_agent_SSO: '', branch:'', phone: '',email: '', });
        setIsEditMode(false);
        setOpen(true);
  };

  // Function to open the modal for editing an existing record
  const handleOpen = (item) => {
        setFormData(item); // Populate form data with the selected item's data
        setIsEditMode(true); // Set edit mode    
        setOpen(true); // Open the modal
    };

  const handleClose = () => {
        setFormData({  agent_id:'', full_names: '', OTA_agent_SSO: '', branch:'', phone: '', email: '',}); // Reset form data
        setIsEditMode(false); // Reset edit mode
        setOpen(false);
  };



  // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value, // Update form data as user types
        }));
    };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
          if(isEditMode){
            console.log(formData);
            await axiosInstance.put(`update_agent/${formData.agent_id}`, formData);
             alert("agent updated successfully!");

          } else {
              await axiosInstance.post('/new_agent', formData);
              alert("agent added successfully!");

          }
           
            handleClose();
          } catch (error) {
            console.error('There was an error adding the branch!', error);
          }
  };
   // Function to open the delete confirmation popup
    const handleDeleteOpen = (item) => {
        setItemToDelete(item); // Set the item to delete
        setDeleteOpen(true); // Open the delete confirmation modal
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false); // Close the delete confirmation modal
        setItemToDelete(null); // Reset the item to delete
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`delete_agent/${itemToDelete.agent_id}`); 
            console.log('agent removed:', response.data);
            alert('agent has been removed sucessfully!');
            handleDeleteClose(); // Close the confirmation modal
            // Optionally update your local state to remove the deleted item from the list
        } catch (error) {
            console.error('can not remove agent at this time:', error);
        }
    };
    //load outlet options from the database using this API
          useEffect(() => {
              axiosInstance.get('/get_outlets')
          .then((response) => {
            // Filter the needed fields (id and outlet)
            const filteredOptions = response.data.map(item => ({
              id: item.id,
              outlet: item.outlet,
            }));
            setOptions(filteredOptions);
          })
          .catch((error) => {
            console.error('Error fetching dropdown data:', error);
          });
      }, []);


    //search agents
    const [data, setData] = useState([]); // Initialize with an empty array
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // Fetch data from the API
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/get_agents'); // Adjust the endpoint as needed
            setData(response.data);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Use useEffect to fetch data on component mount
    useEffect(() => {
        fetchData();
        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 1 second
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    // Use useEffect to filter data based on the search term
    useEffect(() => {
        const results = data.filter(item =>
            Object.values(item).some(value =>
              value !== null && value !== undefined && 
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredData(results);
    }, [searchTerm, data]);

     
     const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; 
    };

//render content
    return (
        <div>
            <section className='tables-section'>
          <h1>Agents</h1>
          
         
                    <Box
                        sx={{
                        display: { xs: 'column', md: 'flex' },
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%',
                        position: 'relative', 
                        zIndex: { xs: 0, md: 0 },
                        marginBottom:'10px'

                        }}
                        >
                        <StyledTextField
                            label="search"
                            variant="outlined"
                            placeholder="search agent"
                            sx={{
                                width: { xs: '100%', md: '400px' }, 
                                marginBottom: { xs: '16px', md: '0' }, 
                            }}
                           
                            slotProps={{
                                input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search/>
                                    </InputAdornment>
                                ),
                                },
                    
                            }}
                            
                            onChange = {(e) => setSearchTerm(e.target.value)} 
                            value = {searchTerm}
                            
                        />

                        
                        <Button
                            variant="contained"  
                            onClick={handleClickOpen}
                            startIcon = {<SupportAgentOutlined  sx = {{fontSize:30}}/> }
                            sx={{
                            backgroundColor: '#001f72',
                            color: '#fff',
                            fontFamily:'Tilt Neon, sans-serif',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            width: { xs: '100%', md: '200px' },
                            marginBottom: { xs: '16px', md: '0' },
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#00bfa5',
                                transition: 'background-color 0.3s ease',
                            },
                                '& .MuiButton-startIcon': {
                                marginRight: '8px', // Add space between the icon and the text
                                },
                            }}
                            
                        >
                            Add Agent
                        </Button>
                        </Box>
           
      
          
           
           <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>OTA/SSO No.</th>
                              <th>Outlet</th>
                              <th>Contact</th>
                              <th>Email</th>
                              <th>Date Added</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (filteredData.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.full_names}</td>
                                <td>{row.OTA_agent_SSO}</td>
                                <td>{row.branch}</td>
                                <td>{row.phone}</td>
                                <td>{row.email}</td>
                                <td>{formatDate(row.date_added)}</td>
                                <td>
                                  {/* <span className="action-remove">remove</span> */}
                                  <Edit onClick={() => handleOpen(row)} 
                                  sx={{ cursor: 'pointer' ,
                                  color:'#05256060', marginRight:'5px', 
                                  '&:hover': {
                                     color: '#052560',
                                     transition: 'color 0.3s ease',
                                  }, }}/>
                                  <DeleteForever onClick={() => handleDeleteOpen(row)}  
                                  sx={{ cursor: 'pointer', color:'#05256060',
                                  '&:hover': {
                                    color: 'red',
                                    transition: 'color 0.3s ease',
                                    }, }}/>
                                </td>
                              </tr>
                            ))
                            ): (
                            <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>
                                <Typography variant="body2" color="#052560">
                                    No records found!
                                </Typography>
                            </td>
                            </tr>
                        )}
                          </tbody>
                          
                        </table>
                        <p style={{fontWeight: 600, fontStyle:'bold', fontFamily:'Quicksand, sans-serif'}}>total agents : {filteredData.length} </p>
               
              </div>    
        </section>

        
        
        {/* The Popup itself */}
      {open && (
       <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h3>{isEditMode ? 'Edit Agent' : 'Add Agent'}</h3>
          <button className="close-popup-btn" onClick={handleClose}>
            &times;
          </button>
        </div>

        <div className="popup-content">
          <form onSubmit={handleSubmit}>
            <StyledTextField
              margin="dense"
              name="full_names"
              label="Full Name"
              required
              fullWidth
              variant="outlined"
              value={formData.full_names}
              onChange={handleChange}
            />
            <StyledTextField
              margin="dense"
              name="OTA_agent_SSO"
              label="OTA_agent_SSO"
              fullWidth
              required
              variant="outlined"
              value={formData.OTA_agent_SSO}
              onChange={handleChange}
            />
           < StyledTextField
              label="Select Branch"
              variant="outlined"
              fullWidth
              select
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              required
             
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.outlet}>
                  {option.outlet}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              margin="dense"
              name="phone"
              label="Contact"
              fullWidth
              variant="outlined"
              required
              value={formData.phone}
              onChange={handleChange}
            />
              <StyledTextField
              margin="dense"
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              required
              value={formData.email}
              onChange={handleChange}
            />

            <div className="popup-actions">
              <button type="button" className="popup-btn" onClick={handleClose}>
                Cancel
              </button>
              <button type="submit" className="popup-btn">
                {isEditMode ? 'Save' : 'Add'}
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
      )}

      {/* delete Popup */}
        {deleteOpen && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h3>Remove Agent</h3>
                            <button className="close-popup-btn" onClick={handleDeleteClose}>
                                &times;
                            </button>
                        </div>

                        <div className="popup-content">
                            <p>Are you sure you want to remove <span style={{color:'orange'}}>'{itemToDelete?.full_names}'</span> permanently?</p>
                            <div className="popup-actions">
                                <button type="button" className="popup-btn" onClick={handleDeleteClose}>
                                    Cancel
                                </button>
                                <button type="button" className="popup-btn" onClick={handleDelete}>
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
      
    );
}

export default Agents;