import React, { useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { AuthContext } from './AuthContext'; 

const ProtectedRoute = ({ element }) => {
  const { user, loading } = useContext(AuthContext);

  // If loading, show a loading indicator
  if (loading) {
    return <div>Loading...</div>;
  }

  // If user is authenticated, render the component; otherwise, redirect
  return user && user.token ?  element : <Navigate to="/" />;
};

export default ProtectedRoute;
