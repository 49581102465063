

import React, { useContext } from 'react';
import './styles/topNav.css';
import Logo from '../assets/logo_blue.svg'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../utils/AuthContext';


const TopNav = ({ onMenuClick }) => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate(); 

//handle logout 
  const handleLogout = () => { 
    logout();
    navigate('/');
  };

  return (
    <div className="topnav">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
        <span className="company-name">Masma Communication Limited</span>
      </div>
      <div className="user-info">
        <span className="greeting">{user && <p>Welcome, {user.username || 'User'}!</p>}</span>
        <button className="logout-button" onClick={handleLogout}>Log Out</button>
      </div>
      <div className="hamburger" onClick={onMenuClick}>
         <MenuOutlinedIcon sx={{ fontSize: 24}}/>
      </div>
  </div>
  );
};

export default TopNav;
