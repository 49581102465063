import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Routes, Route ,useNavigate, Link, Navigate } from 'react-router-dom';
import logo from '../assets/logo_light_blue.svg';
import ProtectedRoute from '../utils/ProtectedRoute'; 
//icons


import './admin-dashboard.css';  

//components
import TopNav from '../admin-components/topNav';
import Sidebar from '../admin-components/sideBar';


import Home from './pages/home';
import Agents from './pages/agents';
import Outlets from './pages/outlets';
import Submissions from './pages/submissions';
import Distributions from './pages/ditributions';


import LiveClock from '../utils/LiveClock';




const AdminDashboard= () => {

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

   // State to manage the active component
  const [activeComponent, setActiveComponent] = useState(localStorage.getItem('activeComponent') || 'home');

    useEffect(() => {
    localStorage.setItem('activeComponent', activeComponent);
  }, [activeComponent]);

  // Function to switch the active component
  const renderComponent = () => {
    switch (activeComponent) {
      case 'home':
        return <Home  setActiveComponent={setActiveComponent} />;
      case 'agents':
        return <Agents/>;
      case 'distributions':
        return <Distributions />;
      case 'submissions':
        return <Submissions/>;
      case 'outlets':
        return <Outlets />;
      default:
        return <Home />;
    }
  };
  


 //General Search  
 const search = () => {

 }
//Date filter 
 const dateFilter = () => {

 }



  return (
   
    <div className="app-container">
      <TopNav onMenuClick={toggleSidebar} />
      <div className="main-container">
        <Sidebar  isOpen={isSidebarOpen} 
                  setActiveComponent={setActiveComponent} 
                  onLinkClick={toggleSidebar} />
        <div className="content">
         
         {/* header with image and logo and text.  */}
          <header className="header-section">
            <div className = "header-background">
              <div className="header-content">
                <div className="date"><LiveClock/></div>
                
                <div className="header-logo">
                  <img src= {logo} alt = "Masma Communications Limited"/>
                  <h1>Masma Communications Limited</h1>
                </div>
              </div>
            </div>           
           </header>

           {renderComponent()}
         
        </div>
      </div>
    </div>
    
  );
};

export default AdminDashboard;
