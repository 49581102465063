import React, { useEffect,useState } from 'react';
import StatCard from '../../admin-components/statcard';
import axiosInstance from '../../utils/config';
import {SupportAgentOutlined,StoreOutlined,PendingActions,Task,MenuOutlined} from '@mui/icons-material';


//sample table data
  

const Home= ({exportToexcel, setActiveComponent}) => {

   const [stats, setStats] = useState({
    outlets: 0,
    agents:0,
    pending:0,
    submitted:0

  
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const outletsResponse = await axiosInstance.get('/get_outlets_count');
        const agentsResponse = await axiosInstance.get('/get_agents_count');
        const pendingResponse = await axiosInstance.get('/pending_serial_count');
        const submittedResponse = await axiosInstance.get('/submitted_serial_count');

        setStats({

          outlets: outletsResponse.data.count,
          agents: agentsResponse.data.count,
          pending: pendingResponse.data.count,
          submitted: submittedResponse.data.count,
        });
        
        setLoading(false);
      } catch (error) {
        setError('Error fetching stats');
        setLoading(false);
      }
    };
  
  const intervalId = setInterval(() => {
            fetchStats(); // Fetch data every 3 seconds
        }, 1000);

        return () => clearInterval(intervalId);
   
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

    return (

        <div>
        {/* Statistics card */}
        <section className='stats'>
        {/* <h1>Quick Stats</h1> */}
        <div className="stat-cards">
            <StatCard title="OUTLETS"
            value={stats.outlets} 
            description = "spread across the country operating 24/7" 
            icon={<StoreOutlined sx={{ fontSize: 60, color: '#052560' }}/>} 
            />


            <StatCard title="AGENTS"
             value={stats.agents} 
             description = "accross all Masma Outlets commited to server" 
             icon={<SupportAgentOutlined sx={{ fontSize: 60, color: 'grey' }}/>} 
             />
            
            <StatCard 
            title="PENDING" 
            value={stats.pending} 
            description = "serial numbers awaiting issuance and submission"
            icon={<PendingActions sx={{ fontSize: 60, color: 'orange' }}/>} />
            
            <StatCard 
            title="SUBMITTED" 
            value= {stats.submitted}  
            description = "serial numbers issued to our clients countrywide" 
            icon={<Task sx={{ fontSize: 60, color: 'green' }}/>} />
        </div>
        </section>
         
        {/* Table setions comes here
        <section className='tables-section'>
          <h1>Sumbmissions</h1>
          <button onClick={exportToexcel}>Export To excel</button>
           <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Branch</th>
                              <th>Location</th>
                              <th>Manager</th>
                              <th>Contact</th>
                              <th>Date Added</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.branch}</td>
                                <td>{row.location}</td>
                                <td>{row.manager}</td>
                                <td>{row.contact}</td>
                                <td>{row.dateAdded}</td>
                                <td>
                                  <span className="action-remove">remove</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                <div className="view-more" onClick={() => setActiveComponent('outlets')}>View All</div>
              </div>    
        </section> */}
        </div>
    );


}

export default Home;