import {React} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';  
import './App.css';

import { AuthProvider } from './utils/AuthContext';
import ProtectedRoute from './utils/ProtectedRoute'; 

import AdminLogin from './admin/pages/admin-login';

import AdminDashboard from './admin/admin-dashboard';



// Define the scrollbar styles
const scrollbarStyles = (theme) => ({
  '::-webkit-scrollbar': {
    width: '10px',  // Width for vertical scrollbar
    height: '8px', // Height for horizontal scrollbar
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(5, 55, 96, 0.59)',
    borderRadius: '15px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee',
  },
});

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          ...scrollbarStyles(themeParam),
          // Additional global styles can go here
        },
      }),
    },
  },
});

function App() {
  return (
    <AuthProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      
          <Router>
                <Routes>
                  <Route path="/" element={<AdminLogin/>} />
                  <Route path="/admin_dashboard" element={<ProtectedRoute element={<AdminDashboard />} />} />
                </Routes>
          </Router>

        </ThemeProvider>
  </AuthProvider>
   
  );
}

export default App;
