// src/utils/axiosConfig.js

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://masmakyc.co.ke', // Replace with your API's base URL
  timeout: 20000, // Optional: set a timeout for requests (10 seconds)
  headers: {
    'Content-Type': 'application/json',
  },
});

// You can add interceptors if needed to handle requests or responses globally
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;}
    return config;
  },
  (error) => {
    // Handle request error here
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response before it is passed to then/catch
    return response;
  },
  (error) => {
    // Handle response errors globally (e.g., display error messages)
    return Promise.reject(error);
  }
);

export default axiosInstance;
